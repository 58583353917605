<template>
  <div>
    <div v-if="this.$route.name === 'SupplierDeliveryNote'">
      <SupplierDeliveryNote />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import SupplierDeliveryNote from "@/views/companyApp/supplierDeliveryNote/SupplierDeliveryNote.vue";

export default {
  name: 'SupplierDeliveryNoteParent',
  components: {
    SupplierDeliveryNote
  }
}
</script>
